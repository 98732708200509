body{
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
main{
    margin:0;
    padding:0;
    }

.buttonContainer{

}
.myButton{
    margin: 0;
    padding: 0;
    padding-bottom: 0.4em;
    text-decoration: none;
    color: rgba(230,230,230,1);
    background-color: rgba(255,255,255,0.05);
    border: orange solid 1px;
    transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
    font-weight: lighter;
}
.myButton:hover{
    text-decoration: none;
    color: rgba(50,50,50,1);
    background-color: rgba(255,255,255,0.2);
}
.myButton:focus{
    background-color: rgba(255,255,255,0.8);
    color:black;
}
a,p,input,h1,h2,h3,h4,h5,h6,button{
    font-weight: lighter;
}
footer{
    background-color: #222222;
}
::-webkit-scrollbar {
   width: 4px;
   height: 4px;
}
::-webkit-scrollbar-button {
    width: 100px;
    height: 100px;
}
::-webkit-scrollbar-thumb {
    background: #ffa500;
    border: 1px solid #ffffff;
    border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
    background: #ffa500;
}
::-webkit-scrollbar-thumb:active {
    background: #ffb500;
}
::-webkit-scrollbar-track {
    background: transparent;
    border: 1px dotted #ffffff;
    border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
    background: transparent;
}
::-webkit-scrollbar-track:active {
    background: transparent;
}
::-webkit-scrollbar-corner {
    background: transparent;
}
/**
#banner{
    position: fixed;
    top:90%;
    left:0;
    width:100%;
    height: 10%;
    z-index: 20;
}
.banner{
    position: relative;
    height : 100%;
    border-radius: 0.2rem;
    border: black solid 1px;
}
.banner-header{
    height: 40%;
    border-bottom: 1px solid orange;
}
.banner-body{
    height: 60%;
    background-color: rgba(255,255,255,0.2);
}
**/
@media (orientation: landscape) {
   body, main {
        width: 100vw;
    }
}

@media (orientation: portrait) {
    body, main {
        width: 100vw;
    }
}
@media (max-width: 568px) {
    .display-1{
        font-size: 2rem;
    }
    .display-2{
        font-size: 1.1rem;
    }
    .display-3{
        font-size: 0.9rem;
    }
    .display-4{
        font-size: 0.8rem;
    }
    .display-5{
        font-size: 0.7rem;
    }
    .display-6{
        font-size: 0.5rem;
    }
}
@media (min-width: 568px) and (max-width: 1023px) {
    .display-1{
        font-size: 3rem;
    }
    .display-2{
        font-size: 1.5rem;
    }
    .display-3{
        font-size: 1.4rem;
    }
    .display-4{
        font-size: 1.1rem;
    }
    .display-5{
        font-size: 0.9rem;
    }
    .display-6{
        font-size: 0.7rem;
    }
}
@media (min-width: 1023px) and (max-width:1679px) {
    .display-1{
        font-size: 2.5rem;
    }
    .display-2{
        font-size: 2rem;
    }
    .display-3{
        font-size: 1.8rem;
    }
    .display-4{
        font-size: 1.4rem;
    }
    .display-5{
        font-size: 1.2rem;
    }
    .display-6{
        font-size: 0.8rem;
    }
}
@media (min-width: 1679px) and (max-width:2559px) {
    .display-1{
        font-size: 4.8rem;
    }
    .display-2{
        font-size: 4.5rem;
    }
    .display-3{
        font-size: 3.2rem;
    }
    .display-4{
        font-size: 2.4rem;
    }
    .display-5{
        font-size: 1.8rem;
    }
    .display-6{
        font-size: 1rem;
    }
}
@media (min-width: 2559px) and (max-width:4095px) {
    .display-1{
        font-size: 6rem;
    }
    .display-2{
        font-size: 5.5rem;
    }
    .display-3{
        font-size: 4rem;
    }
    .display-4{
        font-size: 3rem;
    }
    .display-5{
        font-size: 2rem;
    }
    .display-6{
        font-size: 1rem;
    }
}
@media (min-width: 4095px) {
    .display-1{
        font-size: 10vh;
    }
    .display-2{
        font-size: 11rem;
    }
    .display-3{
        font-size: 8rem;
    }
    .display-4{
        font-size: 4.5rem;
    }
    .display-5{
        font-size: 3rem;
    }
    .display-6{
        font-size: 1.5rem;
    }
}